import React, { useEffect, useState } from "react";
import custombg from "../assets/img/IeltsBanner2.jpg";
import customMobilebg from "../assets/img/Ieltsmobilebanner.webp";
import { Link } from "react-router-dom";
import Popup from "./popup/Popup";

type Props = {};

const IeltsBanner = (props: Props) => {
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [showLiveBtn, setShowLiveBtn] = useState(false);
  const currentTime = new Date();
  const currentHour = currentTime.getHours().toString();
  const Sunday = currentTime.getDay().toString() === "0";

  useEffect(() => {
    if (currentHour >= "10" && currentHour < "19" && !Sunday) {
      setShowLiveBtn(true);
    } else {
      setShowLiveBtn(false);
    }
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  return (
    <>
      {/* hero section start */}
      <div id="#customcontainer" className="d-none d-md-block ">
        <div
          className="bg-image d-flex justify-content-center align-items-center"
          style={{
            backgroundImage: `url(${custombg})`,
            height: "542px",
            backgroundSize: "cover",
            backgroundPosition: "center",
            // backgroundColor: "rgba(0, 0, 0, 0.1)",
            // backgroundBlendMode: "overlay",
          }}
        >
          <div className="row landingformlayout w-100 mx-0">
            <div className="col-md-8 ieltslandingcontent">
              <h1 className=" display-3 text-primary font">
                Master <span className="text-danger">IELTS,</span>
              </h1>
              <h4 className="text-secondary">Your Pathway to Study Abroad!</h4>

              <div className="d-flex flex-md-row flex-column top-100 mt-5 gap-3">
                {/* <button
                  className="btn  btn-danger rounded-3 shadow "
                  onClick={handleOpenPopup}
                >
                  <i className="fa fa-download" aria-hidden="true"></i> Study
                  material
                </button> */}
                <Link
                  // to="/level-test"
                  to="https://forms.zohopublic.com/globedwise/form/Testing/formperma/pLx9SyCLJlCfE-y-j9wm4TiDNX3asRDFTECHgfzMcCA"
                  target="_blank"
                  className="btn btn-primary rounded-3 shadow "
                >
                  <i className="fa fa-check" aria-hidden="true"></i> Check your
                  IELTS Level
                </Link>
                {/* {showLiveBtn && (
                  <Link
                    to="https://us02web.zoom.us/meeting/register/tZUkdeyqrzMjGNecczp9zN9yPlUQgZ0cBpV_"
                    target="_blank"
                    className="btn btn-primary rounded-3 shadow "
                  >
                    <i className="fa fa-video" aria-hidden="true"></i> Join our
                    Live IELTS Session Now!
                  </Link>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popup show={showPopup} onClose={handleClosePopup} />

      {/* ------------------ mobile banner ------------------- */}
      <div className="col-md-8 px-0 text-center mb-3 d-block d-md-none">
        <img
          src={customMobilebg}
          className="img-fluid  shadow"
          style={{
            height: "100%",
            width: "100%",
            objectFit: "contain",
            objectPosition: "right",
          }}
        />
        <div className="row   mx-0">
          <div className="d-flex flex-column justify-content-center align-items-center mt-3 gap-3">
            {/* {showLiveBtn && (
              <Link
                to="https://us02web.zoom.us/meeting/register/tZUkdeyqrzMjGNecczp9zN9yPlUQgZ0cBpV_"
                target="_blank"
                className="btn btn-sm btn-danger rounded-3 shadow "
              >
                <i className="fa fa-video" aria-hidden="true"></i> Join our Live
                IELTS Session Now!
              </Link>
            )} */}
            <Link
              // to="/level-test"
              to="https://forms.zohopublic.com/globedwise/form/Testing/formperma/pLx9SyCLJlCfE-y-j9wm4TiDNX3asRDFTECHgfzMcCA"
              target="_blank"
              className="btn btn-primary rounded-3 shadow "
            >
              <i className="fa fa-check" aria-hidden="true"></i> Check your
              IELTS Level for Free
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default IeltsBanner;
