"use client"

import React, { useState } from "react";
import Popup from "./popup/Popup";
import { Link } from "react-router-dom";
import { FaRegHandPointRight } from "react-icons/fa";

type Props = {};

const AboutIelts = (props: Props) => {
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  // Toggle the state between true and false
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <>
      <div className="container mb-4" style={{ textAlign: "justify" }}>
        <p>
          The International English Language Testing System (IELTS) is a premier
          assessment for individuals seeking to study, work, or migrate to
          English-speaking countries. Recognized and trusted by over 11,000
          institutions globally, IELTS is renowned for its comprehensive
          evaluation of English language proficiency across four key areas:
          listening, reading, writing, and speaking.
          {isExpanded && (
            <span>
              With millions of tests conducted annually, it stands as a gold
              standard for measuring language skills in real-world contexts.
              IELTS offers two tailored versions—Academic and General
              Training—ensuring it meets the diverse needs of students,
              professionals, and immigrants. Its rigorous 9-band scoring system
              provides a thorough understanding of a candidate's abilities,
              making it an invaluable resource for universities, employers, and
              immigration authorities to measure English proficiency with
              precision and fairness.
            </span>
          )}
          {/* Toggle Button */}
          <Link to="" onClick={toggleExpand}>
            {isExpanded ? " <--show Less" : " show More-->"}
          </Link>
        </p>

        <h4 className="my-3 " style={{ textAlign: "center" }}>
          IELTS Exam Structure & Format
        </h4>
        <p>
          The IELTS (International English Language Testing System) is designed
          to assess the English language proficiency of non-native speakers. It
          comes in two versions: Academic and General Training. The test is
          divided into four sections: Listening, Reading, Writing, and Speaking.
        </p>
        <p>
          <strong>Listening (30 minutes):</strong> Comprises four recorded
          monologues and conversations, with a total of 40 questions.
          Test-takers listen to each recording once and answer questions related
          to the content. <br />
         <FaRegHandPointRight color="red" className="blinking-button" /> <Link className="border-bottom  border-primary" to="https://forms.zohopublic.com/globedwise/form/EvaluateyourIELTSListeningSkills/formperma/a79cO0ia38aPbNs-sHJ48IPCnqsxFmfOt9M33QX7EZs">
            Evaluate your Listening Skills
          </Link>
        </p>

        <p>
          <strong>Reading (60 minutes):</strong> In the Academic version, there
          are three long texts ranging from descriptive to factual. For the
          General Training version, the texts are extracts from books,
          magazines, and newspapers. Both have 40 questions assessing various
          skills like understanding main ideas, details, and logical argument.
          <br />
          <FaRegHandPointRight color="red" className="blinking-button"/> <Link className="border-bottom  border-primary"  to="https://forms.zohopublic.com/globedwise/form/EvaluateyourIELTSReadingSkills/formperma/lS5sQcNGVNJVxxvMr3qID5dwdcaYiFLlZJSIfLGngNk">
            Evaluate your Reading Skills
          </Link>
        </p>
        <p>
          <strong>Writing (60 minutes):</strong> In the Academic version, Task 1
          requires describing a graph, chart, or diagram, while Task 2 involves
          writing an essay in response to an argument or problem. The General
          Training version includes writing a letter for Task 1 and an essay for
          Task 2.
          <br />
          <FaRegHandPointRight color="red" className="blinking-button"/> <Link className="border-bottom  border-primary"  to="https://forms.zohopublic.com/globedwise/form/EvaluateyourIELTSWritingSkills/formperma/xVSlsYssLidNCAKuailAWcpdmGoACwYa3GHmga5PO5E">
            Evaluate your Writing Skills
          </Link>
        </p>
        <p>
          <strong>Speaking (11-14 minutes):</strong> A face-to-face interview
          divided into three parts: an introduction and interview, a short
          speech on a given topic, and a discussion. The entire test lasts about
          2 hours and 45 minutes, and it aims to provide a comprehensive, fair,
          and accurate assessment of English language proficiency, catering to
          different needs and contexts. It uses a 9-band scoring system to
          measure performance, with each section receiving an individual score
          that contributes to an overall band score, indicating the test taker's
          level of English proficiency.
          <br />
          <FaRegHandPointRight color="red" className="blinking-button"/> <Link className="border-bottom  border-primary"  to="https://us02web.zoom.us/meeting/register/tZUkdeyqrzMjGNecczp9zN9yPlUQgZ0cBpV_#/registration">
            Evaluate your Speaking Skills
          </Link>
        </p>
        <Popup show={showPopup} onClose={handleClosePopup} />
        <button
          className="btn   btn-primary rounded-3 shadow "
          onClick={handleOpenPopup}
        >
          <i className="fa fa-download" aria-hidden="true"></i> Download Study
          material
        </button>
      </div>
    </>
  );
};

export default AboutIelts;
