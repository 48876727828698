import React from "react";
import PopupForm from "./PopupForm";



interface PopupProps {
  show: boolean;
  onClose: () => void;
}

const Popup: React.FC<PopupProps> = ({ show, onClose }) => {
  if (!show) return null;

  return (
    <div style={popupStyles.overlay}>
      <div className="p-2">
        <PopupForm onClose={onClose} />
      </div>
    </div>
  );
};

export default Popup;

// -----------------------Popup Styles ------------------

const popupStyles = {
  overlay: {
    position: "fixed" as "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
    marginTop: "4rem",
  },
};
