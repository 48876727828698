import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button } from "react-bootstrap";
import ielts1 from "../assets/ieltstrf/01.jpeg";
import ielts2 from "../assets/ieltstrf/02.jpeg";
import ielts3 from "../assets/ieltstrf/03.jpeg";
import ielts4 from "../assets/ieltstrf/04.jpeg";
import ielts5 from "../assets/ieltstrf/05.jpeg";
import ielts6 from "../assets/ieltstrf/06.jpeg";
import ielts7 from "../assets/ieltstrf/07.jpeg";
// import Image from "next/image";

const sliderData = [
  {
    id: 1,
    image: `${ielts1}`,
  },
  {
    id: 2,
    image: `${ielts2}`,
  },
  {
    id: 3,
    image: `${ielts3}`,
  },
  {
    id: 4,
    image: `${ielts4}`,
  },
  {
    id: 5,
    image: `${ielts5}`,
  },
  {
    id: 6,
    image: `${ielts6}`,
  },
  {
    id: 7,
    image: `${ielts7}`,
  },
];

type Props = {
  dots?: boolean;
  infinite?: boolean;
  speed?: number;
  slidesToShow?: number;
  slidesToScroll?: number;
  initialSlide?: number;
  autoplay?: boolean;
  autoplaySpeed?: number;
  pauseOnHover?: boolean;
  swipeToSlide?: true;
  arrows?: boolean;
  [key: string]: any;
};

const IeltsSlider = ({}: Props) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const settings = {
    arrrows: true,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    // dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: -1,
    swipeToSlide: true,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          // initialSlide: 2
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="d-md-flex my-4  flex-md-row align-items-center justify-content-center">
      <div className="row container slider-container">
        <div className="section-head col-sm-12 mb-2">
          <h4>
            <span>Some of Our</span> IELTS Students
          </h4>
          {/* <Button variant="primary" onClick={handleShow}>
            Launch demo modal
          </Button> */}
          {/* <GetInTouchModal show={show} onClose={handleClose}/> */}
        </div>
        <Slider {...settings}>
          {sliderData.map((item) => (
            <div
              key={item.id}
              className="testimonialSlider sliderText d-flex flex-column gap-2  align-items-center border rounded-3 my-3 col-md-6"
              style={{ height: "420px" }}
            >
              <div>
                <img
                  src={item.image}
                  alt=""
                  className="img-fluid object-cover"
                />
                {/* <Image
                  src={item.image}
                  alt=""
                  width={100}
                  height={100}
                  layout="responsive"
                  className="rounded-3"
                /> */}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default IeltsSlider;
