import React from 'react'
import { Helmet } from 'react-helmet'

export default function Checkielts() {
  return (
    <>
    <Helmet>
    <title>Check Ielts Level</title>
    <meta name="description" content="GlobEDwise - Study Abroad - Overseas Education and Immigration" />
    <meta name="google-site-verification" content="_AVkTsAnt6UJmKSzOeFHzxvpuxb4-Bsak9Y9_iKw5_k" />
</Helmet>

<div className="zohoform pt-1 bg-light" >
<div className="section-head col-sm-12 mb-1 mt-4">
      <h4><span>Check Your</span>  IELTS Level</h4>
    </div>
    <iframe height='1250px' width='100%' frameBorder='0' scrolling='auto' src='https://forms.zohopublic.com/globedwise/form/Testing/formperma/pLx9SyCLJlCfE-y-j9wm4TiDNX3asRDFTECHgfzMcCA'></iframe>
</div>


</>
  )
}
