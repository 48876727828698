import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Agamdeep from "../assets/testimonials/AgamdeepSingh.png";
import Simran from "../assets/testimonials/newstudent2.jpeg";
import Geetika from "../assets/testimonials/newstudent3.jpeg";
import Harmanjot from "../assets/testimonials/newstudent1.jpeg";
import Harmehar from "../assets/testimonials/HarmeharKaur.png";
import Ivy from "../assets/testimonials/Ivy Singal.png";
import Jeslyn from "../assets/testimonials/JeslynKataria.png";
import Aditi from "../assets/testimonials/ADITI SINGH.png";
import Akshat from "../assets/testimonials/AKSHAT SAXENA.png";
import Mimansha from "../assets/testimonials/MIMANSHA.png";
import Vibhuti from "../assets/testimonials/VIBHUTI BANIWAL.png";
import Vaishali from "../assets/testimonials/VAISHALI HANDA.png";
import Rohit from "../assets/testimonials/ROHIT ANTWAL.png";
import Inderpal from "../assets/testimonials/INDERPAL SINGH.png";
import Kaushal from "../assets/testimonials/KAUSHAL KAUR.png";
import Rinkal from "../assets/testimonials/RINKAL BHATIA.png";
import Parth from "../assets/testimonials/PARTH SHARMA.png";

const newSliderData = [
  { id: 1, image: `${Aditi}` },
  { id: 2, image: `${Akshat}` },
  { id: 3, image: `${Mimansha}` },
  { id: 4, image: `${Vibhuti}` },
  { id: 5, image: `${Vaishali}` },
  { id: 6, image: `${Rohit}` },
  { id: 7, image: `${Inderpal}` },
  { id: 8, image: `${Kaushal}` },
  { id: 9, image: `${Rinkal}` },
  { id: 10, image: `${Parth}` },
];

const sliderData = [
  {
    image: `${Agamdeep}`,
    review:
      "GlobEDwise thrives on excellence! An amazing event at Le Meridien, followed by regular guidance for choosing the best programs, & colleges in Canada. They have helped me immensely in my study visa approval.Cheers to their work!",
    studentName: "Agamdeep Singh Kalra",
    university: "University of Waterloo",
  },
  {
    image: `${Simran}`,
    review:
      "I'd like to begin by expressing my gratitude towards Globedwise. The team was quite helpful in locating the right university that provided the degree I wanted to pursue. They guided me through each stage of the application process, from admission to visa",
    studentName: "Simran Shah",
    university: "Deakin University, Australia",
  },
  {
    image: `${Geetika}`,
    review:
      " Globedwise's service has exceeded my expectations. I strongly recommend Globdwise to students who want to study abroad because it is trustworthy and provides excellent service. Their advice is essential in selecting an appropriate course that will undoubtedly be the greatest.",
    studentName: "Geetika Saini",
    university: "Western Sydney University, Australia",
  },
  {
    image: `${Harmanjot}`,
    review:
      " The globedwise's team was with me every step of the way, from profile review to visa approval. Globedwise assisted me in putting my doubts and concerns My issues and worries were well discussed, and my counselor understood my needs and assisted me in achieving them.",
    studentName: "Harmanjot Singh",
    university: " University of Canberra, Australia",
  },
  {
    image: `${Harmehar}`,
    review:
      "GlobEDwise team helped me in choosing the university and I finalized the University of New South Wales, the Group 8 university. I am very happy with their services and highly recommend GlobEDwise to everyone. ",
    studentName: "Harmehar Kaur",
    university: " University of New South Wales",
  },
  {
    image: `${Ivy}`,
    review:
      "   True to its word!! Globedwise services are the best. I thank the team for helping me secure admission to the UK's most prestigious university. With your guidance & support, my journey for abroad studies became a lot easier.",
    studentName: "Ivy Singal",
    university: "Imperial College, London",
  },
  {
    image: `${Jeslyn}`,
    review:
      "I'd like to thank Globedwise from the bottom of my heart for their unwavering support and excellent assistance throughout my application process. Globedwise was extremely helpful in making my dream a reality.",
    studentName: "Jeslyn Kataria",
    university: "Adelphi University, USA",
  },
];

type Props = {
  dots?: boolean;
  infinite?: boolean;
  speed?: number;
  slidesToShow?: number;
  slidesToScroll?: number;
  initialSlide?: number;
  autoplay?: boolean;
  autoplaySpeed?: number;
  pauseOnHover?: boolean;
  swipeToSlide?: true;
  arrows?: boolean;
  [key: string]: any;
};

const TestimonilSlider = ({}: Props) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const settings = {
    arrrows: true,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          // initialSlide: 2
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="d-md-flex my-5  flex-md-row align-items-center justify-content-center">
      <div className="row container slider-container">
        <div className="section-head col-sm-12">
          <h4>
            <span>What our Students</span> Think About Us
          </h4>
          {/* <Button variant="primary" onClick={handleShow}>
            Launch demo modal
          </Button> */}
          {/* <GetInTouchModal show={show} onClose={handleClose}/> */}
        </div>
        <Slider {...settings}>
          {newSliderData.map((item) => (
            <div
              key={item.id}
              className="testimonialSlider sliderText d-flex flex-column gap-2 py-3 align-items-center  rounded-4  col-md-6"
              style={{ height: "420px" }}
            >
              <img src={item.image} alt="" className="img-fluid object-cover" />
              {/* <div>
                <img
                  src={item.image}
                  alt={item.studentName}
                  className="object-center border img-fluid  rounded-circle"
                  style={{ height: "160px", width: "160px" }}
                />
              </div>
              <div className=" d-flex  justify-content-center flex-column align-items-center">
                <span className="testimonialSlider-title fw-bolder fs-5">
                  {item.studentName}
                </span>
                <p className="fw-bold" style={{ color: "#0093dd" }}>
                  {item.university}
                </p>
              </div>

              <div className=" d-flex justify-content-center align-content-center ">
                <span style={{ textAlign: "center", fontWeight: "normal" }}>
                  {item.review}
                </span>
              </div> */}
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default TestimonilSlider;
