import React, { useEffect, useState } from "react";
import logo from "../../assets/img/globedwiselogo.png";
import { Link } from "react-router-dom";
import { FaPhoneAlt } from "react-icons/fa";

type Props = {};

const MainHeader = (props: Props) => {
  const [showLiveBtn, setShowLiveBtn] = useState(false);
  const currentTime = new Date();
  const currentHour = currentTime.getHours().toString();
  const Sunday = currentTime.getDay().toString();

  useEffect(() => {
    if (currentHour >= "10" && currentHour < "19" && Sunday !== "0") {
      setShowLiveBtn(true);
    } else {
      setShowLiveBtn(false);
    }
  }, []);

  return (
    <div
      className=" d-flex justify-content-between px-4 py-3 align-items-center sticky-top bg-white "
      style={{ height: "4rem", zIndex: 99 }}
    >
      <div className="col-md-4 col-5 p-md-2">
        <Link to="/">
          <img
            src={logo}
            alt=""
            className="w-100"
            style={{ maxWidth: "14rem" }}
          />
        </Link>
      </div>
      <div className="d-flex ">
        {!showLiveBtn && (
          <Link
            to="tel:+91 9696875875"
            className="btn btn-sm btn-outline-danger rounded-3 shadow fw-bold "
          >
            <FaPhoneAlt /> +91-9696875875
          </Link>
        )}
        {/* <Link
          // to="/level-test"
          to="https://forms.zohopublic.com/globedwise/form/Testing/formperma/pLx9SyCLJlCfE-y-j9wm4TiDNX3asRDFTECHgfzMcCA"
          target="_blank"
          className="btn btn-sm btn-outline-danger rounded-3 shadow "
        >
          <i className="fa fa-check" aria-hidden="true"></i> Check your IELTS
          Level
        </Link> */}
        {showLiveBtn && (
          <Link
            to="https://us02web.zoom.us/meeting/register/tZUkdeyqrzMjGNecczp9zN9yPlUQgZ0cBpV_"
            target="_blank"
            className="btn btn-sm btn-danger blinking-button rounded-3 shadow "
          >
            <i className="fa fa-video" aria-hidden="true"></i> Join IELTS
            Session!
          </Link>
        )}
      </div>
    </div>
  );
};

export default MainHeader;
