import React from "react";
import MainForm from "../MainForm";
import IeltsFormPopupImg from "../../assets/img/IeltsStudyMaterial.jpeg";

interface ContactFormProps {
  onClose: () => void;
}

const PopupForm: React.FC<ContactFormProps> = ({ onClose }) => {
  return (
    <>
      <div className="d-md-flex bg-white mx-auto outline">
        <div className="d-md-flex align-items-center d-sm-none d-none ">
          <img src={IeltsFormPopupImg} alt="" className="img-fluid  shadow" />
        </div>
        <div className="">
          <MainForm Lead_Source="IELTS Landing Page" />
          <div className="px-3 pb-2" style={{ textAlign: "center" }}>
            <button
              className="btn btn-outline-danger btn-block mt-2 shadow-sm rounded-3 w-100"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupForm;
