import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>

    <div className="container-fluid bg-light text-dark footer pt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center">
        <h2 className="text-primary my-3">Ease & Expertise Of <br/>
        Overseas Education</h2>

        <a href="https://crm.zoho.com/bookings/GlobedwiseStudyAbroadCounsellingSession?rid=ca404eb1ef7a80447dfc8a423120b3fc026adab58d270808dba169946447be87gidd698835d2c511585591874e9c4ef4f12b20a68a5b65ef8257513fb943617476e" target="_blank" className="btn btn-outline-dark btn-lg mx-2 my-2">Book Counselling</a>
        <a href="/" className="btn btn-primary btn-lg mx-2 my-2">Contact Us</a>
        </div>

        <div className="container py-5">
            <div className="row g-5">
                <div className="col-lg-3 col-md-6">
                    <h4 className="text-primary mb-3">Overseas Education</h4>
                    <a className="btn px-0" href="javascript:void(0)">Why Overseas Education</a>
                    <a className="btn px-0" href="javascript:void(0)">High-Quality Education</a>
                    <a className="btn px-0" href="javascript:void(0)">Opportunities for Work</a>
                    <a className="btn px-0" href="javascript:void(0)">Guide to Overseas Education</a>
                    <a className="btn px-0" href="javascript:void(0)">Benefits for Overseas Education</a>
                    <a className="btn px-0" href="javascript:void(0)">Why Canada ?</a>
                </div>
                <div className="col-lg-3 col-md-6">
                    <h4 className="text-primary mb-3">IELTS</h4>
                    <a className="btn px-0" href="javascript:void(0)">Why IELTS From GlobEDwise</a> <br/>
                    <a className="btn px-0" href="javascript:void(0)">About IELTS</a> <br/>
                    <a className="btn px-0" href="javascript:void(0)">Key Features</a> <br/>
                    <a className="btn px-0" href="javascript:void(0)">Testimonials</a> <br/>
                    <a className="btn px-0" href="javascript:void(0)">Study Abroad</a> <br/>
                    <a className="btn px-0" href="javascript:void(0)">Check your IELTS level</a> <br/>
                </div>
                <div className="col-lg-4 col-md-6">
                    <h4 className="text-primary mb-3">Our Locations</h4>
                    <p className="mb-3"><a className="text-primary font-weight-bold" target="_blank" href="https://goo.gl/maps/zgD3Jy5bnKVvFi8N9"><i className="fa fa-map-marker-alt me-1 text-primary"></i>Prashant Vihar, New Delhi <br />
                    <span className="text-dark">A-54, Prashant Vihar, Opposite Lancer Convent School, Sector - 14, Rohini,</span> 
                    </a> </p>
                    <p className="mb-3"><a className="text-primary font-weight-bold" target="_blank" href="https://goo.gl/maps/ggZJbkNmuahBXmCH6"><i className="fa fa-map-marker-alt me-1 text-primary"></i>Rajouri Garden, New Delhi <br />
                    <span className="text-dark">J-4/1, Rajouri Garden, New Delhi - 110027, India</span>
                    </a> </p>
                    <p className="mb-3"><a className="text-primary font-weight-bold" target="_blank" href="https://goo.gl/maps/mvqPQhazxwZzKCFj6"><i className="fa fa-map-marker-alt me-1 text-primary"></i>Vijay Nagar, New Delhi <br />
                   <span className="text-dark"> H-15, Vijay Nagar, Delhi University Area, Delhi - 110009, India</span></a> </p>
                    <p className="mb-3"><a className="text-primary font-weight-bold" target="_blank" href="https://goo.gl/maps/UX2MipmyjoG8qVA47"><i className="fa fa-map-marker-alt me-1 text-primary"></i>Chandigarh, <br />
                   <span className="text-dark"> SCO 315-316, First Floor, Sector 35B, Chandigarh -160022, India</span>
                    </a> </p>
                </div>
              
                <div className="col-lg-2 col-md-6">
                <h4 className="text-primary mb-3">Destinations</h4>
                    <a className="btn px-0" href="javascript:void(0)">United States</a><br />
                    <a className="btn px-0" href="javascript:void(0)">United Kingdom</a><br />
                    <a className="btn px-0" href="javascript:void(0)">Canada</a><br />
                    <a className="btn px-0" href="javascript:void(0)">New Zealand</a><br />
                    <a className="btn px-0" href="javascript:void(0)">Australia</a><br />
                    <a className="btn px-0" href="javascript:void(0)">Many More</a><br />
                </div>
            </div>
        </div>
        <div className="container">
            <div className="copyright">
                <div className="row">
                    <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                        &copy; <a className="border-bottom text-danger" href="javascript:void(0)">Copyright © 2023 GlobEDwise</a>, All Right Reserved.
                    </div>
                    <div className="col-md-6 text-center text-md-end">
                    <div className="socail1">
    <a className="customicon mx-2" href="https://twitter.com/globedwise" target="_blank"><i className="fab fa-twitter"></i></a>
    <a className="customicon mx-2" href="https://www.linkedin.com/company/globedwise/" target="_blank"><i className="fab fa-linkedin"></i></a>
    <a className="customicon mx-2" href="https://www.facebook.com/Globedwise/" target="_blank"><i className="fab fa-facebook"></i></a>
    <a className="customicon mx-2" href="https://www.youtube.com/@globedwisestudyabroad/featured" target="_blank"><i className="fab fa-youtube"></i></a>
    <a className="customicon mx-2"href="https://www.instagram.com/globedwise_india/" target="_blank"><i className="fab fa-instagram"></i></a>    
    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up"></i></a>


   
    
    </>
  );
}
