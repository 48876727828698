import axios from "axios";
import React, { useEffect, useState } from "react";
// import { useNavigate } from 'react-router-dom';
// import { Helmet } from 'react-helmet'
import idpimg from "../assets/img/idp-logo.svg";
import keyfeature from "../assets/img/keyfeature.png";
// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
// import custombg from "../assets/img/carousel-4.jpg";

import banner from "../assets/img/banner.jpg";
import { Link, useNavigate } from "react-router-dom";
import Select, { MultiValue } from "react-select";

import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBAccordion,
  MDBAccordionItem,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import WhyChoose from "./WhyChoose";
import ReasonsAbroad from "./ReasonsAbroad";
import { Helmet } from "react-helmet";
import NewTesti from "./NewTesti";
import YoutubeVideo from "./YoutubeVideo";
import Pdfdownload from "./Pdfdownload";
import Mobilepdfdownload from "./Mobilepdfdownload";
import Mobilequicksteps from "./Mobilequicksteps";
import Infopopup from "./Infopopup";
import Quicksteps from "./Quicksteps";
import Mobilequick from "./Mobilequick";
import IeltsForm from "./IeltsForm";
import ShowIeltsPopup from "./ShowIeltsPopup";
import TestimonilSlider from "./TestimonilSlider";
import IeltsBanner from "./IeltsBanner";
import AboutIelts from "./AboutIelts";
import IeltsSlider from "./IeltsSlider";
import MainForm from "./MainForm";

export default function Ielts() {
  // thankyoumodal

  // thankyoumodal
  const navigate = useNavigate();

  const formurl = "https://backend.globedwise.com/zoho";
  const [formData, setformData] = useState({
    firstname: "",
    lastname: "",
    emailaddress: "",
    phone: "",
    location: "",
    destination: "",
  });

  type formData = {
    firstname: string;
    lastname: string;
    emailaddress: string;
    phone: string;
    // destination: string;
  };

  const handleglobeInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const newData = { ...formData, [name]: value };
    setformData(newData);
    setShowMessage(false);
  };

  // const destinations = ['Canada', 'USA', 'United Kingdom','Australia','France','Georgia','Germany','Switzerland','New Zealand','Latvia']; // Add more destinations as needed

  // const handleSelectChange = (selectedOptions: MultiValue<{ value: string; label: string }> | { value: any }[]) => {
  //   const selectedDestinations = selectedOptions.map((option: { value: any }) => option.value);
  //   setformData({ ...formData, destination: selectedDestinations });
  // };

  const [showMessage, setShowMessage] = useState(false);

  const isValidEmail = (email: string) => {
    // Basic email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (
      formData.firstname === "" ||
      formData.lastname === "" ||
      !isValidEmail(formData.emailaddress) ||
      formData.phone.length !== 10
    ) {
      setShowMessage(true);
      return;
    }

    axios
      .post(formurl, {
        data: [
          {
            Last_Name: formData.lastname,
            First_Name: formData.firstname,
            Email: formData.emailaddress,
            Mobile: formData.phone,
            Location: formData.location,
            Campaign_Which_Country_you_want_to_study_abroad:
              formData.destination,
            Form_URL: "ielts.globedwise.com",
            Lead_Source: "IELTS Landing Page",
            Lead_Stage: "New",
          },
        ],
        trigger: ["approval", "workflow", "blueprint"],
        lar_id: "3654871000006222333",
      })
      .then((response) => {
        setformData({
          firstname: "",
          lastname: "",
          emailaddress: "",
          phone: "",
          location: "",
          destination: "",
        });
        // alert('Your Form is Submitted')
        console.log(response);
        alert("Your form is submitted");
        navigate("/thankyou");
      })
      .catch((error) => {
        console.log(error);
        // Handle error and display appropriate message to the user
      });
  };
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  //   tab js start
  const [justifyActive, setJustifyActive] = useState("tab1");

  const handleJustifyClick = (value: string) => {
    if (value === justifyActive) {
      return;
    }

    setJustifyActive(value);
  };
  //   tab js ended

  return (
    <>
      <Helmet>
        <title>Expert IELTS Coaching - Globedwise</title>
        <meta
          name="description"
          content="Enroll in IELTS Coaching and gain Tips to Clear IELTS Exam"
        />
        <meta
          name="google-site-verification"
          content="_AVkTsAnt6UJmKSzOeFHzxvpuxb4-Bsak9Y9_iKw5_k"
        />
      </Helmet>
      <IeltsBanner />
      {/* <ShowIeltsPopup /> */}
      {/* hero section ended */}
      {/* <div id="whyielts">
        <WhyChoose />
      </div> */}
      {/* modal form started */}
      <div
        className="modal fade pt-4 mt-5"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ zIndex: "9999" }}
      >
        <div className="modal-dialog modal-dialog-scrollable ">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Please Fill Your Details
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="landingform">
                <form>
                  <div className="form-outline mb-1">
                    <div className="form-outline mb-1">
                      <label
                        className="form-label fw-bold "
                        style={{ marginLeft: "0px" }}
                      >
                        First Name
                      </label>
                      <input
                        required
                        type="text"
                        name="firstname"
                        className="form-control rounded-3 input-light2"
                        onChange={(e) => handleglobeInput(e)}
                        value={formData.firstname}
                        placeholder="Enter Your First name"
                      />
                      <div className="form-notch">
                        <div
                          className="form-notch-leading"
                          style={{ width: "9px" }}
                        ></div>
                        <div className="form-notch-middle"></div>
                        <div className="form-notch-trailing"></div>
                      </div>
                    </div>

                    <div className="form-outline mb-1">
                      <label
                        className="form-label fw-bold "
                        style={{ marginLeft: "0px" }}
                      >
                        Last Name
                      </label>
                      <input
                        required
                        type="text"
                        name="lastname"
                        className="form-control rounded-3 input-light2"
                        onChange={(e) => handleglobeInput(e)}
                        value={formData.lastname}
                        placeholder="Enter Your Last name"
                      />
                      <div className="form-notch">
                        <div
                          className="form-notch-leading"
                          style={{ width: "9px" }}
                        ></div>
                        <div className="form-notch-middle"></div>
                        <div className="form-notch-trailing"></div>
                      </div>
                    </div>

                    <div className="form-outline mb-1">
                      <label
                        className="form-label fw-bold "
                        style={{ marginLeft: "0px" }}
                      >
                        Email address
                      </label>
                      <input
                        required
                        type="email"
                        name="emailaddress"
                        className="form-control rounded-3 input-light2"
                        onChange={(e) => handleglobeInput(e)}
                        value={formData.emailaddress}
                        placeholder="Enter Your Email Address"
                      />
                      <div className="form-notch">
                        <div
                          className="form-notch-leading"
                          style={{ width: "9px" }}
                        ></div>
                        <div className="form-notch-middle"></div>
                        <div className="form-notch-trailing"></div>
                      </div>
                    </div>

                    <div className="form-outline mb-1">
                      <label
                        className="form-label fw-bold "
                        style={{ marginLeft: "0px" }}
                      >
                        Mobile No.
                      </label>
                      <input
                        required
                        type="number"
                        name="phone"
                        className="form-control rounded-3 input-light2"
                        onChange={(e) => handleglobeInput(e)}
                        value={formData.phone}
                        placeholder="Enter Your Mobile No."
                      />
                      <div className="form-notch">
                        <div
                          className="form-notch-leading"
                          style={{ width: "9px" }}
                        ></div>
                        <div className="form-notch-middle"></div>
                        <div className="form-notch-trailing"></div>
                      </div>
                    </div>

                    {/* <label className="form-label fw-bold " style={{marginLeft: "0px"}}>Select Date</label>
                  <input required type="datetime-local" name="date" className="form-control rounded-3 input-light2" onChange={(e)=> handleglobeInput(e)} value={formData.date}/> */}
                  </div>

                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="btn btn-danger btn-block mt-4 shadow-sm rounded-3 w-100"
                  >
                    Register Here
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* modal form ended */}
      {/* tabs start */}
      {/* mobile form  */}
      <div className="d-flex align-items-center justify-content-center d-md-none mb-3">
        <MainForm Lead_Source="IELTS Landing Page" />
      </div>
      {/* ------------------ mobile form -----------  */}

      <div
        className="container section-head col-sm-12 mt-3 mb-2"
        id="aboutielts"
      >
        <h4>
          <span>About</span> IELTS{" "}
        </h4>
      </div>

      {/* ------------------------- About Details ------------------------- */}
      <AboutIelts />

      {/* <div className="testprepsec mx-3 mt-5 wow fadeInUp">
        <MDBTabs justify className="mb-3">
          <MDBTabsItem>
            <MDBTabsLink
              className="testpreptab col-6"
              onClick={() => handleJustifyClick("tab1")}
              active={justifyActive === "tab1"}
            >
              About IELTS
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink
              className="testpreptab d-none d-md-block"
              onClick={() => handleJustifyClick("tab2")}
              active={justifyActive === "tab2"}
            >
              Why IELTS
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink
              className="testpreptab d-none d-md-block"
              onClick={() => handleJustifyClick("tab3")}
              active={justifyActive === "tab3"}
            >
              IELTS for life skills and UKVI
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink
              className="testpreptab d-none d-md-block"
              onClick={() => handleJustifyClick("tab4")}
              active={justifyActive === "tab4"}
            >
              Computer delivered IELTS
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink
              className="testpreptab"
              onClick={() => handleJustifyClick("tab5")}
              active={justifyActive === "tab5"}
            >
              IELTS structure and format
            </MDBTabsLink>
          </MDBTabsItem>
        </MDBTabs>

        <MDBTabsContent>
          <MDBTabsPane show={justifyActive === "tab1"}>
            <div className="containerr py-2 my-5">
              <div className="container">
                <div className="row g-5">
                  <div className="col-lg-12" data-wow-delay="0.3s">
                    <div className="col-sm-12"></div>
                    <p className="mb-4">
                      The International English Language Testing System (IELTS)
                      is the world’s most popular and recognized English
                      language proficiency test for study, work and migration,
                      with more than three million tests taken in the past year.
                    </p>
                    <p className="mb-4">
                      The IELTS results are recognized by more than 10,000
                      organizations, including educational institutions,
                      employers, professional associations and governments, in
                      140 countries around the world. IELTS is a test of all
                      four language skills such as - Listening, Reading, Writing
                      and Speaking. Listening, Reading and Writing tests shall
                      be conducted on the same day one after the other, with no
                      breaks in between. For the Speaking test, you can book a
                      slot online on your preferred date and time. If you fail
                      to choose a slot within the stated time period, a time
                      slot will be automatically allocated to you.
                    </p>
                    <p className="mb-4">
                      The total test time is under three hours. You can choose
                      to take your IELTS test either on paper or computer,
                      depending on your convenience. There is no difference in
                      the content, format or level of difficulty between the two
                      options. Remember, you will have to carefully choose which
                      module you wish to take - IELTS Academic or IELTS General
                      Training, depending on the purpose of taking test.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </MDBTabsPane>
          <MDBTabsPane show={justifyActive === "tab2"}>
            <div className="containerr py-2 my-5">
              <div className="container">
                <div className="row g-5">
                  <div className="col-lg-12" data-wow-delay="0.3s">
                    <div className="col-sm-12"></div>
                    <p className="mb-2">
                      <strong> For academic purposes − </strong> thousands of
                      the world’s most reputable universities and colleges will
                      accept your IELTS results as evidence of your English
                      language proficiency.
                    </p>
                    <p className="mb-2">
                      <strong> For working professionals − </strong>{" "}
                      professional registration bodies in many fields will
                      accept an IELTS result, including accounting, engineering,
                      law, medicine, nursing, pharmacy and teaching bodies in
                      many countries. This means that after completing your
                      studies, you may need to take the test in order to gain
                      professional registration in an English-speaking country.
                    </p>
                    <p className="mb-2">
                      <strong>For migration and PR purposes –</strong> IELTS
                      scores are required by governments in more countries than
                      any other English language test as a requirement for
                      permanent residency. Unlike other test providers, the
                      IELTS General Training test is the only non-academic test
                      available for migration to Australia, New Zealand, Canada,
                      The United Kingdom and The USA.
                    </p>
                    <p className="mb-2">
                      <strong>It’s the test that’s fairer to you – </strong>{" "}
                      IELTS assesses you on your practical communication
                      abilities and provides with an accurate assessment of the
                      four skills being tested. It lays emphasis on on testing
                      the language abilities rather than specialist knowledge of
                      the candidate and offer two versions – Academic and
                      General Training. Plus, it allows for a one-on-one
                      Speaking test one with an examiner in a private room with
                      no distractions.
                    </p>
                    <p>
                      The test content for IELTS is developed by an
                      international team of experts and undergoes extensive
                      research to ensure the test remains fair and unbiased for
                      any candidate regardless of nationality, background,
                      gender, lifestyle or location.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </MDBTabsPane>
          <MDBTabsPane show={justifyActive === "tab3"}>
            <div className="containerr py-2 my-5">
              <div className="container">
                <div className="row g-5">
                  <div className="col-lg-12" data-wow-delay="0.3s">
                    <div className="col-sm-12"></div>
                    <p className="mb-4">
                      <strong>
                        IELTS Life Skills is a test for those who need to prove
                        their English speaking and listening skills to acquire a
                        UK VISA, and is available at Common European Framework
                        of Reference for Languages (CEFR) levels A1 and B1. It
                        is designed to meet the requirements of UKVI for certain
                        visa categories and other immigration purposes.
                      </strong>
                    </p>
                    <p className="mb-2 mx-4">
                      -- IELTS Life Skills A1: If you are applying for a ‘family
                      of a settled person’ visa
                    </p>
                    <p className="mb-2 mx-4">
                      -- IELTS Life Skills B1: If you are applying for a
                      ‘indefinite leave to remain or citizenship' visa
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </MDBTabsPane>
          <MDBTabsPane show={justifyActive === "tab4"}>
            <div className="containerr py-2 my-5">
              <div className="container">
                <div className="row g-5">
                  <div className="col-lg-12" data-wow-delay="0.3s">
                    <div className="col-sm-12"></div>
                    <p className="mb-2">
                      In order to opt for computer-delivered IELTS, you take the
                      Listening, Reading and Writing components using a desktop
                      computer. All other things related to the test remains the
                      same as paper-based IELTS test, including, Content,
                      Question types, Timings and Marking. The timings when you
                      take IELTS on computer are slightly different from the
                      paper-based test, before attempting any part of the
                      Listening test, you'll have some time to read the
                      questions and before finishing the Listening test, you'll
                      be given two minutes to review your answers. Duration of
                      the Listening test is approx. 30 - 36 minutes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </MDBTabsPane>
          <MDBTabsPane show={justifyActive === "tab5"}>
            <div className="containerr py-2 my-5">
              <div className="container">
                <div className="row g-5">
                  <div className="col-lg-12" data-wow-delay="0.3s">
                    <div className="col-sm-12"></div>
                    <p className="mb-4">
                      <strong>The four parts of the IELTS test</strong>
                    </p>
                    <p className="mb-2 mx-4">
                      {" "}
                      -- Listening: 30 minutes (plus 10 minutes' transfer time)
                    </p>
                    <p className="mb-2 mx-4"> -- Reading: 60 minutes</p>
                    <p className="mb-2 mx-4"> -- Writing: 60 minutes</p>
                    <p className="mb-4 mx-4"> -- Speaking: 11–14 minutes</p>

                    <p className="mb-4">
                      The test total time is: 2 hours and 45 minutes. Listening,
                      Reading and Writing are completed in one sitting. The
                      Speaking test may be taken on the same day or up to seven
                      days before or after the other tests. All test takers take
                      the same Listening and Speaking tests, while the Reading
                      and Writing tests differ depending on whether the test
                      taker is taking the Academic or General Training versions
                      of the test.
                    </p>

                    <ul className="custList">
                      <li>Listening: </li>
                    </ul>
                    <p>
                      The module comprises four sections, with ten questions in
                      each section. It takes 40 minutes: 30 - for testing, plus
                      10 for transferring the answers to an answer sheet.{" "}
                    </p>
                    <p className="mb-0">
                      Sections 1 and 2 are about everyday, social situations.
                    </p>
                    <ul className="pl-4">
                      <li>
                        Section 1 has a conversation between two speakers (for
                        example, a conversation about travel arrangements)
                      </li>
                      <li>
                        Section 2 has one person speaking (for example, a speech
                        about local facilities).
                      </li>
                    </ul>
                    <p className="mb-0">
                      Sections 3 and 4 are about educational and training
                      situations
                    </p>
                    <ul className="pl-4">
                      <li>
                        Section 3 is a conversation between two main speakers
                        (for example, a discussion between two university
                        students, perhaps guided by a tutor)
                      </li>
                      <li>
                        Section 4 has one person speaking about an academic
                        subject.{" "}
                      </li>
                    </ul>
                    <p>
                      Each section begins with a short introduction telling the
                      test taker about the situation and the speakers. Then they
                      have some time to look through the questions. The
                      questions are in the same order as the information in the
                      recording, so the answer to the first question will be
                      before the answer to the second question, and so on. The
                      first three sections have a break in the middle allowing
                      test takers to look at the remaining questions. Each
                      section is heard only once.
                    </p>
                    <p>
                      At the end of the test students are given 10 minutes to
                      transfer their answers to an answer sheet. Test takers
                      will lose marks for incorrect spelling and grammar.
                    </p>
                    <ul className="custList">
                      <li>Reading: </li>
                    </ul>
                    <p>
                      The Reading paper has three sections and texts totaling
                      2,150-2,750 words. There will be a variety of question
                      types, such as multiple choice, short-answer questions,
                      identifying information, identifying writer's views,
                      labeling diagrams, completing a summary using words taken
                      from the text and matching information/headings/features
                      in the text/sentence endings. Test takers should be
                      careful when writing down their answers as they will lose
                      marks for incorrect spelling and grammar.
                    </p>
                    <p className="mb-0">Texts in IELTS Academic</p>
                    <ul className="pl-4">
                      <li>
                        Three reading texts, which come from books, journals,
                        magazines, newspapers and online resources written for
                        non-specialist audiences. All the topics are of general
                        interest to students at undergraduate or postgraduate
                        level.[20]
                      </li>
                    </ul>
                    <p className="mb-0">Texts in IELTS General Training</p>
                    <ul className="pl-4">
                      <li>
                        Section 1 contains two or three short texts or several
                        shorter texts, which deal with everyday topics. For
                        example, timetables or notices – things a person would
                        need to understand when living in an English-speaking
                        country.
                      </li>
                      <li>
                        Section 2 contains two texts, which deal with work. For
                        example, job descriptions, contracts, training
                        materials.
                      </li>
                      <li>
                        Section 3 contains one long text about a topic of
                        general interest. The text is generally descriptive,
                        longer and more complex than the texts in Sections 1 and
                        2. The text will be taken from a newspaper, magazine,
                        book or online resource.{" "}
                      </li>
                    </ul>
                    <ul className="custList">
                      <li>Writing: </li>
                    </ul>
                    <p>
                      The Writing paper has two tasks which must both be
                      completed. In task 1 test takers write at least 150 words
                      in about 20 minutes. In task 2 test takers write at least
                      250 words in about 40 minutes. Test takers will be
                      penalised if their answer is too short or does not relate
                      to the topic. Answers should be written in full sentences
                      (test takers must not use notes or bullet points).
                    </p>
                    <p className="mb-0">IELTS Academic</p>
                    <ul className="pl-4">
                      <li>
                        Task 1: test takers describe a graph, table, chart or
                        diagram in their own words.
                      </li>
                      <li>
                        Task 2: test takers discuss a point of view, argument or
                        problem. Depending on the task, test takers may be
                        required to present a solution to a problem, present and
                        justify an opinion, compare and contrast evidence,
                        opinions and implications, and evaluate and challenge
                        ideas, evidence or an argument.
                      </li>
                    </ul>
                    <p className="mb-0">IELTS General Training</p>
                    <ul className="pl-4">
                      <li>
                        Task 1: test takers write a letter in response to a
                        given everyday situation. For example, writing to an
                        accommodation officer about problems with your
                        accommodation, writing to a new employer about problems
                        managing your time, writing to a local newspaper about a
                        plan to develop a local airport.
                      </li>
                      <li>
                        Task 2: test takers write an essay about a topic of
                        general interests. For example, whether smoking should
                        be banned in public places, whether children's leisure
                        activities should be educational, how environmental
                        problems can be solved.
                      </li>
                    </ul>

                    <ul className="custList">
                      <li>Speaking: </li>
                    </ul>
                    <p>
                      The speaking test is a face-to-face interview between the
                      test taker and an examiner.
                    </p>
                    <p className="mb-0">
                      The speaking test contains three sections.
                    </p>
                    <ul className="pl-4">
                      <li>
                        Section 1: introduction and interview (4–5 minutes).
                        Test takers may be asked about their home, family, work,
                        studies, hobbies, interests, reasons for taking IELTS
                        exam as well as other general topics such as clothing,
                        free time, computers and the Internet.
                      </li>
                      <li>
                        Section 2: long turn (3–4 minutes). Test takers are
                        given a task card about a particular topic. Test takers
                        have one minute to prepare to talk about this topic. The
                        task card states the points that should be included in
                        the talk and one aspect of the topic which must be
                        explained during the talk. Test takers are then expected
                        to talk about the topic for 2 minutes, after which the
                        examiner may ask one or two questions.
                      </li>
                      <li>
                        Section 3: discussions (4–5 minutes). The third section
                        involves a discussion between the examiner and the test
                        taker, generally on questions relating to the theme
                        which they have already spoken about in Section 2.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </MDBTabsPane>
        </MDBTabsContent>
      </div> */}
      {/* tabs edned */}
      {/* key features start */}
      <div className="bg-light py-4 mb-5" id="keyfeatures">
        <div className="keyformlayout row w-100 mx-0 align-items-center">
          <div className="col-md-8">
            <div className="section-head col-sm-7">
              <h4>
                <span>Key Features of </span> Our Course
              </h4>
            </div>

            <ul className="bannerPoints text-dark">
              <h5 className="mb-3 ullist">
                <i className="fa fa-check-circle"></i> Free access to the full
                length Mock test and latest sample papers
              </h5>
              <h5 className="my-3 ullist">
                <i className="fa fa-check-circle"></i> Three pronged strategy to
                hone and upgrade IELTS skills{" "}
              </h5>
              <h5 className="my-3 ullist">
                <i className="fa fa-check-circle"></i> Separate IELTS Writing
                Classes{" "}
              </h5>
              <h5 className="my-3 ullist">
                <i className="fa fa-check-circle"></i> Special Sessions to get
                rid-off the Fear of Speaking English{" "}
              </h5>
              <h5 className="my-3 ullist">
                <i className="fa fa-check-circle"></i> Small class size to give
                personalised approach in IELTS training{" "}
              </h5>
              <h5 className="my-3 ullist">
                <i className="fa fa-check-circle"></i> Special and dedicated
                classes for grammar and vocabulary{" "}
              </h5>
              <h5 className="my-3 ullist">
                <i className="fa fa-check-circle"></i> Certified Trainers{" "}
              </h5>
              <h5 className="my-3 ullist">
                <i className="fa fa-check-circle"></i> Obsession for Student
                Satisfaction{" "}
              </h5>
              <h5 className="my-3 ullist">
                <i className="fa fa-check-circle"></i> Focused Learning{" "}
              </h5>
            </ul>
            <Link
              to="https://globedwise.zohobookings.com/#/studyabroadonlinecounseling"
              className="btn btn-danger btn-block mt-2 mx-5 shadow-sm rounded-3"
            >
              Book Your Online IELTS Demo Class
            </Link>
          </div>
          <div className="col-md-4">
            <div className="landingform py-3">
              <img src={keyfeature} className="img-fluid" alt="key features" />
            </div>
          </div>
        </div>
      </div>
      {/* key features ended */}
      <IeltsSlider />
      {/* <YoutubeVideo /> */}
      <ReasonsAbroad />

      <TestimonilSlider />
      {/* faq start */}
      <div className="section-head col-sm-12 mt-5" id="faq">
        <h4>
          <span>Frequently Asked</span> Questions
        </h4>
      </div>
      <div className="container">
        <MDBAccordion alwaysOpen initialActive={1}>
          <MDBAccordionItem
            collapseId={1}
            headerTitle="What is the duration of validity for an IELTS test score?"
          >
            An IELTS test score normally is valid for two years after the exam
            date. Institutions or organizations that require IELTS results for
            admission or other purposes may no longer consider the score as
            current or valid after the two-year period.
          </MDBAccordionItem>
          <MDBAccordionItem
            collapseId={2}
            headerTitle="How does Globedwise help students prepare for their IELTS Exam?"
          >
            Globedwise is a trusted platform that assists students in their
            preparation for the IELTS Exam. The trainers at Globedwise are
            certified by IDP, ensuring their expertise and proficiency in IELTS
            training. Students can benefit from regular mock tests and
            preparation exams provided by these trainers, enabling them to
            perform at their optimal potential before taking the actual exam.
            Globedwise strives to equip students with the necessary skills and
            knowledge to succeed in the IELTS Exam.
          </MDBAccordionItem>
          <MDBAccordionItem
            collapseId={3}
            headerTitle="Why are there two versions of IELTS?"
          >
            There are two types of IELTS tests: IELTS Academic and IELTS General
            Training. The IELTS Academic test is for individuals who want to
            pursue higher education or professional registration in an
            English-speaking environment. It evaluates your readiness to begin
            studying or training and includes features of academic language. On
            the other hand, the IELTS General Training test is intended for
            those who plan to go to English-speaking countries for higher
            education, work experience, or training programs. It is also a
            requirement for migration to Australia, Canada, New Zealand, and the
            UK. This test focuses on essential skills needed in various social
            and workplace situations. While the Listening and Speaking sections
            are the same for both tests, the Reading and Writing sections differ
            in terms of the topics covered depending on the test you choose.
          </MDBAccordionItem>
          <MDBAccordionItem
            collapseId={4}
            headerTitle="How many times can you take the IELTS?"
          >
            Candidates have the flexibility to retake the test without any
            specific time restrictions, allowing them to attempt it multiple
            times in order to achieve their desired score.
          </MDBAccordionItem>
          <MDBAccordionItem
            collapseId={5}
            headerTitle="What if I need to postpone or cancel my IELTS test?"
          >
            To make changes to your IELTS test booking, you need to contact the
            test center where you initially scheduled your test. If you decide
            to postpone or cancel your application more than 5 weeks prior to
            the test date, you will receive a refund after deducting the
            administration charges. However, if you choose to postpone or cancel
            within 5 weeks of the test date, the full fee will be charged unless
            you can provide a valid medical justification. In case of a serious
            medical condition, if you provide a medical certificate within 5
            days of the test date, you will receive a refund after deducting the
            local administrative cost. In situations where circumstances are
            beyond your control, such as a transport strike, causing a delay,
            the test center may offer you a test on the next available date
          </MDBAccordionItem>
        </MDBAccordion>
      </div>
      {/* faq ended */}
      {/* <Infopopup/> */}
    </>
  );
}
