"use client";

import React, { useState } from "react";
import axios from "axios";
// import { useRouter } from "next/navigation";
// import Link from "next/link";
// import { toast } from "react-toastify";
// import { ImSpinner9 } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { MdOutlinePhonelinkRing } from "react-icons/md";


const MainForm = ({ Lead_Source }: { Lead_Source: string }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [formData, setformData] = useState({
    
    fullname: "",
    emailaddress: "",
    phone: "",
  });
  const formurl = "https://backend.globedwise.com/zoho";

  type formData = {
    
    fullname: string;
    emailaddress: string;
    phone: string;
  };

  const handleglobeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newData = { ...formData, [name]: value };
    setformData(newData);
    setShowMessage(false);
  };

  const isValidEmail = (email: string) => {
    // Basic email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  // console.log(formData);

  // ------------------downloading multiple pdfs on successfull form submit ------------------

  const downloadPDFs = async () => {
    try {
      // Simulated URLs of the uploaded PDFs (replace with actual URLs)
      const pdfUrls = ["/studyMaterial/Mock-1-Aca.pdf","/studyMaterial/Mock-2-Aca.pdf","/studyMaterial/Mock-3-Aca.pdf"]; // we can add more pdf by seperating them with ',' in this array to download.

      // Fetch and download both PDFs
      const pdfPromises = pdfUrls.map(async (url) => {
        const response = await axios.get(url, { responseType: "blob" });
        const filename = url.split("/").pop(); // Extract filename from URL
        saveAs(response.data, filename); // Download the PDF
      });

      await Promise.all(pdfPromises); // Wait until both files are downloaded
    } catch (error) {
      console.error("Error downloading PDFs:", error);
    }
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setLoading(true);
    if (
      
      formData.fullname === "" ||
      !isValidEmail(formData.emailaddress) ||
      formData.phone.length !== 10
    ) {
      setShowMessage(true);
      return;
    }

    try {
      const res = await axios.post(formurl, {
        data: [
          {
            Last_Name: formData.fullname,
            
            Email: formData.emailaddress,
            Mobile: formData.phone,
            Lead_Source: Lead_Source,
          },
        ],
        trigger: ["approval", "workflow", "blueprint"],
        lar_id: "3654871000006222333",
      });
      console.log(res.config);

      //   const pdfDownloadLink = document.createElement('a');
      //   pdfDownloadLink.href = 'BHMS_Student_Handbook.pdf';// Replace with the actual URL of your PDF file
      //   pdfDownloadLink.download = 'BHMS_Student_Handbook.pdf'; // Specify the filename for the downloaded file
      //   pdfDownloadLink.click();

      navigate("/thankyou");
      //   toast.success("Form Submitted Successfully..!", {
      //     position: "top-center",
      //     theme: "light",
      //   });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
    await downloadPDFs();
  };

  return (
    <>
      <div>
        <div
          className="formhead bg-danger pt-3 pb-2 px-3"
          style={{ width: "320px", textAlign: "center" }}
        >
          <h5 className="text-light ">
            Download Study Material <MdOutlinePhonelinkRing />
          </h5>
        </div>
        <div className="landingform bg-white shadow py-3 px-3">
          {showMessage && (
            <div className="alert alert-danger" role="alert">
              Please fill correct details.
            </div>
          )}
          <form>
            {/* <div className="form-outline mb-1">
              <label
                className="form-label fw-bold "
                style={{ marginLeft: "0px" }}
              >
                First Name
              </label>
              <input
                required
                type="text"
                name="firstname"
                className="form-control rounded-3 input-light2"
                onChange={(e) => handleglobeInput(e)}
                defaultValue={formData.firstname}
                placeholder="Enter Your First name"
                tabIndex={0}
              />
              <div className="form-notch">
                <div
                  className="form-notch-leading"
                  style={{ width: "9px" }}
                ></div>
                <div className="form-notch-middle"></div>
                <div className="form-notch-trailing"></div>
              </div>
            </div> */}

            <div className="form-outline mb-1">
              <label
                className="form-label fw-bold "
                style={{ marginLeft: "0px" }}
              >
                Full Name
              </label>
              <input
                required
                type="text"
                name="fullname"
                className="form-control rounded-3 input-light2"
                onChange={(e) => handleglobeInput(e)}
                defaultValue={formData.fullname}
                placeholder="Enter Your Full Name"
                tabIndex={0}
              />
              <div className="form-notch">
                <div
                  className="form-notch-leading"
                  style={{ width: "9px" }}
                ></div>
                <div className="form-notch-middle"></div>
                <div className="form-notch-trailing"></div>
              </div>
            </div>

            <div className="form-outline mb-1">
              <label
                className="form-label fw-bold "
                style={{ marginLeft: "0px" }}
              >
                Email address
              </label>
              <input
                required
                type="email"
                name="emailaddress"
                className="form-control rounded-3 input-light2"
                onChange={(e) => handleglobeInput(e)}
                defaultValue={formData.emailaddress}
                placeholder="Enter Your Email Address"
                tabIndex={0}
              />
              <div className="form-notch">
                <div
                  className="form-notch-leading"
                  style={{ width: "9px" }}
                ></div>
                <div className="form-notch-middle"></div>
                <div className="form-notch-trailing"></div>
              </div>
            </div>

            <div className="form-outline mb-1">
              <label
                className="form-label fw-bold "
                style={{ marginLeft: "0px" }}
              >
                Mobile No.
              </label>
              <input
                required
                type="number"
                name="phone"
                className="form-control rounded-3 input-light2"
                onChange={(e) => handleglobeInput(e)}
                defaultValue={formData.phone}
                placeholder="Enter Your Mobile No."
                tabIndex={0}
              />
              <div className="form-notch">
                <div
                  className="form-notch-leading"
                  style={{ width: "9px" }}
                ></div>
                <div className="form-notch-middle"></div>
                <div className="form-notch-trailing"></div>
              </div>
            </div>

            <button
              type="submit"
              onClick={handleSubmit}
              className="btn btn-danger btn-block mt-2 shadow-sm rounded-3 w-100"
              disabled={
                loading ||
                
                !isValidEmail(formData.emailaddress) ||
                formData.phone.length !== 10
              }
            >
              {/* {loading && (
                <ImSpinner9
                  size={20}
                  className="mx-2"
                  style={{ animation: "spin 1s linear infinite" }}
                />
              )} */}
              {loading ? "Submitting..." : "Register Now"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default MainForm;
